import React from 'react';
import image1 from './images/1.png';
import image2 from './images/2.png';
import image3 from './images/3.png';
import image7 from './images/7.png';
import image8 from './images/8.png';
import image9 from './images/9.png';
import image10 from './images/10.png';
import image11 from './images/11.png';
import image13 from './images/13.png';
import image14 from './images/14.png';
import image15 from './images/15.png';
import image16 from './images/16.png';
import image17 from './images/17.png';
import image18 from './images/18.png';
import image20 from './images/20.png';
import image21 from './images/21.png';
import image22 from './images/22.png';
import image23 from './images/23.png';
import image24 from './images/24.png';
import image25 from './images/25.png';
import image26 from './images/26.png';
import image27 from './images/27.png';
import image28 from './images/28.png';
import image29 from './images/29.png';
import image30 from './images/30.png';
import image31 from './images/31.png';
import image32 from './images/32.png';
import image33 from './images/33.png';
import image34 from './images/34.png';
import image35 from './images/35.png';
import image36 from './images/36.png';
import image37 from './images/37.png';
import image38 from './images/38.png';

function Home() {
    return (
      <>
        <section className="fullimage">
          <img src={image1} alt="you can fail at what you do so you might as well take a chance on what you love" className="image" />
        </section>
  
        <div id="repetition-does-not-spoil-the-prayer" className="halfcontent">
          <div className="text">
            <p>Repetition does not spoil the prayer</p>
            <p>Repetition is deeply woven into how we learn and evolve.</p>
            <p>You can turn any song into a hit if you play it for an audience 3 times in 24 hours. Most of the songs that you love, you love because they've been repeated to you. How many songs do you fall in love with on the first play? How many songs do you love from the radio?</p>
            <p>To sell to others, and more importantly, to sell to yourself you have to be repetitive. I hate book summaries because the point of reading the book is so you get hypnotized into their thinking. The point of reading the entire book cover-to-cover is for the author to repeat the same information enough times so it becomes part of you. That you can put down the book and carry that information into your life without constantly having to look up whatever point they made.</p>
            <p>The process of reading well-written writing is where the author guides you through their thought process, repeatedly emphasizing their points until they resonate. You internalize the author's mimetic models. By repeatedly engaging with a narrative the reader internalizes the content and the values effectively sharing their own desires and understanding of the world.</p>
            <p>If you believe in what you are selling you have a moral obligation to hammer the same message; to deeply ingrain the essence of what you're offering into the fabric of everyday life.</p>
            <p>Repetition is important because we don't get the point the first time. We live in this state where we are perpetually bombarded with information that it's really difficult to disseminate the signal from the noise. You can use emphasis but the easiest way is to repeat your values. You want to deliver information the easiest way to yourself and others.</p>
            <p>Rituals reinforce social cohesion and identity. You are not preaching to a standing army. You are preaching to a moving parade.</p>
            <p>Repetition becomes a battleground for creating and disrupting marketing. Startups don't introduce new products or services, they create new desires or tap into existing ones in unique ways. Repetition reshapes their desires making the offering integral to their identity that they can't help but seek out. We exist now in a crisis of differentiation where the only way to survive is to alter your repetition to be fundamentally different. What will you die on a hill for?</p>
          </div>
          <div className="image">
            <img src={image2} alt="Background" />
          </div>
        </div>
  
        <section className="fullimage">
          <img src={image3} alt="Image 2" className="image" />
        </section>
  
        <div id="belief-before-ability" className="halfcontent">
          <div className="image">
            <img src={image10} alt="Background" />
          </div>
          
          <div className="text">
            <p>Belief before ability</p> 
            <p>The world that we live in is extremely fragile. If the big bang was 1 inch to the right or 1 degree colder we may not be here. There is this notion that civilization is so persistent, so durable, so long lasting. But we can change that at any moment.</p> 
            <p>For all known history, everyone successful has had some crazy idea before they could execute that idea. They believe that they could do it before they did it. It's obvious.</p> 
            <p>Before the first line of code, the first dollar, the first hire there has to be a belief, a conviction that something fundamentally new and valuable can be created. This belief is what drives the relentless pursuit building and problem solving through the inevitable setbacks.</p> 
            <p>The most successful companies today, owned by world builders, were not born in a vacuum of existing abilities or guaranteed outcomes. They started out as beliefs in the mind of founders. These beliefs often defied the current understanding of what was possible or profitable.</p> 
            <p>Startups are inherently nonlinear. The skills needed to disrupt industries or create new paradigms are often not available at the outset. They are created, built, and developed through the journey. True leadership is the ability to see beyond the present into the fog of war on what others can't see yet.</p> 
            <p>The greatest achievements in human history have come from venturing into uncharted territory where abilities have not been tested and the outcome is not certain. Belief grounds you in these unknowns armed with the conviction that we will build the boat before it sinks.</p> 
            <p>The fundamental belief in the possibility of creating something entirely new followed by the relentless pursuit of abilities to make that belief a reality.</p> <p>One thing that keeps me up at night is the video of the fruit flies in the glass container. So many of us keep hitting our heads on this hallucinatory container only to realize that we could jump as high as we wanted to. If we only acted within the confines of our current ability where would we as a civilization be?</p> 
            <p>We are all against significant odds. If we had a 99% chance of failure, we would still be doing what we are doing. The best companies of our generation haven't been created yet. They lie in the minds of others who think someone else is working on this or that they're not enough or that they can't take on that much risk right now. These ideas will slowly die, depriving our civilization of the future. Depriving our civilization of greatness.</p>
          </div>
        </div>
  
        <section className="fullimage">
          <img src={image8} alt="Image 2" className="image" />
        </section>
  
        <div id="focus-on-long-term-excellence" className="halfcontent">
          <div className="text">
            <p>Focus on long term excellence</p> 
            <p>Not enough people focus on long term excellence. They focus on a 10 year or 100 year plan. What about a 10,000 year plan? What does your company look like then?</p> 
            <p>I love the game civilization because it forces you to make suboptimal decisions. You don't have enough money or time to defend and attack. You have to choose one.</p> 
            <p>When looking for opportunities, coworkers, friends, I'm looking for people who I want my grandkids to be friends with. One of my favorite accomplishments is that I want to work with you the rest of my life.</p> 
            <p>When you focus on long term excellence, real long term. You focus on what matters. Same with reminding yourself that you are going to die. The non important things in life like what color your car is or if you got a parking ticket disappear. What you are left with are the things that truly matter in life. That you are making an impact and driving humanity forward.</p> 
            <p>There are so many things that I see other people worrying about in life. Problems that don't really matter. We need to focus on the larger problems first. The ones that really matter. Only then can we focus on the ones that don't matter. Not that we'll ever get there.</p> 
            <p>Viewed from a financial lens, short term gains often come at the expense of sustainable growth and innovation. This encourages focus on the foundational innovations and the creations of value that endures and expands over time. The best filter for great ideas is time. It's why I think that we should be reading more old books or foreign business books.</p> 
            <p>Building something that lasts 10,000 years is incredibly difficult. It has incredibly higher standards than building something that will last 1 year. It's a testament to building worlds that outlast the founders, technologies that potentially serve generations far beyond our current reality. It makes you consider problems differently and build in a way that you have an incredibly solid infrastructure. You are optimizing for survival.</p> 
            <p>When you build for 10,000 years you create a culture of excellence that permeates every level of society.</p> 
            <p>When we build we often overlook the time factor. When we think about creating something we're not just building for the immediate future. The real test of impact is if what we can create can endure, adapt and continue after we cease to exist. Religion is an amazing example of this.</p> 
            <p>Are we addressing truly fundamental human needs and questions? When we focus on long term excellence we shift our pursuit to the principles to solve fundamental problems, not surface level profitable issues like vr porn.</p>
            <p>What kind of world are we building towards? Are we laying the foundation for further discovery? Are the worlds that we are building enhancing the human experience or contributing to a fleeting sense of novelty? </p>
          </div>
          <div className="image">
            <img src={image15} alt="Background" />
          </div>
        </div>
  
        <section className="fullimage">
          <img src={image11} alt="Image 2" className="image" />
          </section>
  
        <div id="anxious-to-capitalize-on-every-waking-hour" className="halfcontent">
          <div className="image">
            <img src={image7} alt="Background" />
          </div>
          <div className="text">
          <p>Anxious to capitalize on every waking hour</p> 
          <p>Relentless pace of execution and the understanding that every working hour has the potential to build us closer to our mission of making the world a fundamentally better place.</p> 
          <p>It's not about the quantity of time spent working but the quality and intensity of focus during those hours. Every second not experimenting allows the competitors to catch up or pass us.</p> 
          <p>The challenges that we're tackling are monumental in scope and scale. They demand an extraordinary level of commitment, innovation, and most importantly time. Every waking hour spent on expanding the boundaries 1% beyond what we currently think is possible is an hour invested in securing a better future for humanity.</p> 
          <p>Every waking hour we can learn, iterate, and evolve. It transforms into what might otherwise be considered wasted time as valuable lessons that propel us forward.</p> 
          <p><em>"Respect. Feels attached. Time spent doing this is time well spent. That's what it's all about? Yeah I think so."</em></p> 
          <p><em>"I learned the most about him when I was packing up his house.</em></p> 
          <p><em>All his belongings of his whole life., and I found this stash of pocket notebooks that he must have taken when he was on tour and full of all these details like the palm trees he'd seen or escargot he tried or this time the ocean looked purple.</em></p> 
          <p><em>And the way he wrote everything it was like a reminder. Like don't forget this moment or don't forget this interesting strange detail. Hundreds of these entries. And he signed off the same way every time.</em></p> 
          <p><em>Every second counts."</em></p> 
          <p>I wrote about this previously. TLDR: The way that we honor our limited time here is to be fully present in each moment.</p>
          </div>
        </div>
  
        <section className="fullimage">
          <img src={image16} alt="Image 2" className="image" />
          </section>
  
        <div id="the-ten-commandments-must-be-silenced-when-self-preservation-is-at-stake" className="halfcontent">
          <div className="text">
          <p>The ten commandments must be silenced when self preservation is at stake</p> 
          <p>Man is a competitive animal and his states must be like himself.</p> 
          <p>On a long enough timeline the survival rate for everyone is zero. That's what we're fighting against.</p> 
          <p>To survive, play the game long enough. Playing infinite games broadens the landscape beyond short-term wins and losses and enhances the map to a more realistic representation of life which has dynamic systems than the prisoner's dilemma or other simplistic game theory exercises.</p> 
          <p>We are never not in war time. We should constantly have a war time feel where we ruthlessly eliminate costs and focus on long term plays that enable our survival.</p> 
          <p>Our warfare must be silent, our movements unseen until the moment of strike. Do not forget that our greatest enemy is complacency. The moment that we believe the battle is won we've lost. The landscape is constantly changing so we must adapt with every action that we take.</p> 
          <p>If we don't build the thing that kills facebook, nobody will.</p> 
          <p>There are no final victories, only the next battle. The real victory is surviving one more hour. That is how we win.</p> 
          <p>Huang encouraged his employees to continue shipping products with a sense of desperation, and for years to come he opened staff presentations with the word "Our company is thirty days from going out of business."</p> 
          <p>It's about harnessing the storm and turning it into our advantage. Most don't have this mindset. It sees beyond conventional boundaries, one that finds pathways where others see walls. Where the true artist comes out to play. It's about seeing potential where others see impossibility.</p>
          </div>
          <div className="image">
            <img src={image9} alt="Background" />
          </div>
        </div>
  
        <section className="fullimage">
          <img src={image14} alt="Image 2" className="image" />
          </section>
  
      <div id="if-you-can't-handle-the-heat-stay-out-of-the-fucking-kitchen" className="halfcontent">
        <div className="image">
          <img src={image28} alt="Background" />
        </div>
        <div className="text">
          <p>If you can't handle the heat stay out of the fucking kitchen.</p> 
          <p>I love the bear. I love it because of the idea of mise en place. Because true cooking is an art form. True cooking is when you move oil on glass. If you couldn't see someone's resume what question would you ask? After you have to hire them or not. One question. For me: What is the most complicated course you've ever cooked? Anyone can cook: the problem like the game civilization is that when you cook in a restaurant you have limited time. You have to move extremely efficiently and that means setting beforehand to ensure that you are successful.</p> 
          <p>Preparation</p> 
          <p>Organization</p> 
          <p>Efficiency</p> 
          <p>Mise en place: Everything in its place.</p> 
          <p>The margin for error is minimal and the complexity is vast. You need mise en place because you need the meticulous preparation and organization necessary for these high stakes environments. It's about having every component, every piece of data, every team member aligned when we execute. This precision ensures that when we launch we can trust in our preparation and rely on it when we get a setback.</p> 
          <p>We think of startups wrong. We think that we need hard work and this endless cycle of coding. That we need to grind it out. That we need to push ourselves to complete exhaustion for it to work. It doesn't have to be like that. We can have hard work while moving effortlessly. While moving like oil on glass. Where each movement is the smallest possible distance. True mise en place is where you have clarity of thought.</p> 
          <p>Mise en place and working in a restaurant fosters a culture where discipline, precision, and attention to detail are required. Where every moment is directed towards an ambition goal.</p> 
          <p>Comprehensive preparation that when we are faced with decisions we have a clear framework to operate. Every idea, data point, and strategy we create a structured environment that gives deep thinking and bold decisions the space.</p> 
          <p>Mise en place gives us the confidence to navigate uncharted waters with agility and insight. Reading the signals in real-time and adjusting your course without losing sight of your overarching vision.</p> 
          <p>Execute something incredibly complex with a time constraint.</p> 
          <p>Cooking, at its highest form, is an art that requires not just technical skill but a deep understanding of materials, a sense of timing, and the ability to adapt.</p> 
          <p>Having a mise en place mindset means you've already anticipated potential challenges and outcomes. This preparation allows for more informed, confident decision-making, even under pressure.</p> 
          <p>The ability to adapt while staying true to the overall mission.</p>
        </div>
      </div>
  
      <section className="fullimage">
          <img src={image27} alt="Image 2" className="image" />
          </section>
  
      <div id="we-may-not-be-the-first-to-the-party-but-we'll-be-the-best" className="halfcontent">
        <div className="text">
        <p>We might be fashionably late. We may not be the first with the idea but we'll redefine the game, set new standards, and create experiences that were previously unimaginable.</p> 
        <p>We might enter spaces that have paths already threaded. When entering we'll take time to observe, learn, and jump over the work that you've done. We won't merely introduce another product. We'll reimagine these categories completely focusing on user experience, design, and integration in ways that haven't been done before.</p> 
        <p>We may not be the first restaurant but we'll ask questions on why you designed something like that and the purpose of design. We'll take your ideas and strip them to the point where the core idea still exists but the execution is completely different.</p> 
        <p>This approach requires an unparalleled level of dedication to craftsmanship, a deep empathy for the user, and a relentless pursuit of perfection.</p> 
        <p>We go beyond learning from the mistakes of those who came before us. We don't want to exist in space but rather to fundamentally enhance how people connect or experience life. We're always the new kid. But we're not weighed down by expectations or rules on how this should be. We learn the rules so that we can break them.</p> 
        <p>The problems and ideas that we're looking to solve aren't new. We finally have the tools where it is feasible to solve them. That is what we are trying to do.</p>
        </div>
        <div className="image">
          <img src={image25} alt="Background" />
        </div>
      </div>
  
      <section className="fullimage">
          <img src={image21} alt="Image 2" className="image" />
          </section>
  
      <div id="fuck-the-safety-net" className="halfcontent">
        <div className="image">
          <img src={image24} alt="Background" />
        </div>
        <div className="text">
          <p>This is me taking control - from Sloan, from the Fraternity, from Janice, from billing reports, from ergonomic keyboards, from cheating girlfriends and sack-of-sh\*\*t best-friends. This is me taking back control - of my life. \[Breaks the fourth wall, turning to gaze at the audience\] What the f\*\*\* Have you done anything lately?_</p> 
          <p>The safety net that you think you have is an illusion. You don't have a secure job, a secure girlfriend, a secure friendship. You have to work on these things everyday. You have to keep these things alive. And for what? You work so hard to be in a job that you hate. To be with friends who you are not in love with. To have a girlfriend who you don't love. If we extend the timeline to 1000 years it leads us to a life of complacency and mediocrity.</p> 
          <p>It's an epiphany about what it truly means to take control of our destinies.</p> 
          <p>If you knew you couldn't fail, what would you do? It would probably be a drastically different life than what you have now. It would probably be a happier life than what you have now. And the problem with this is that you could have a life where you don't fail. The problem is that we keep ourselves in these lives that we don't like because we don't think that we deserve them.</p> 
          <p>The difference between extreme risk and what we think as secure is a 4 inch drop. It's where you live marginally worse to be completely free. To build the things you want to build. To eat the food you want to eat. To live the life that you want to live. Capital H happiness, true happiness, comes after peak discomfort. That only comes from when you temporarily step outside of your safety net. What if our whole life could be peak happiness?</p> 
          <p>The path that is fraught with uncertainty and devoid of conventional safety is often the one that leads to meaningful and lasting change.</p> <p>What if we saw risk a s an unnecessary component to a well-lived life. That the real security we seek, an authentic life, is found in the willingness to let go of the safety net and to have faith that it will work out. That everything will be ok in the end.</p> 
          <p>The differences in a life with and without risk are marginal. Going up to the girl at the bar, asking your boss for a raise, whatever it is. Trivial differences. These two lives end up in dramatically different places because of how they compound. What if we could cultivate a continuous state of engagement and fulfillment.</p>
        </div>
      </div>
  
      <section className="fullimage">
          <img src={image18} alt="Image 2" className="image" />
          </section>
      
      <div id="when-art-critics-get-together-they-talk-about-form-and-structure-and-meaning" className="halfcontent">
        <div className="text">
        <p>When art critics gather, their discussions revolve around abstract concepts like form, structure, and meaning. They dissect and analyze the intricate details of artistic expression from a theoretical perspective. In contrast, artists' conversations are grounded in practicality; they exchange tips on where to buy affordable supplies like turpentine, essential for their craft. This contrast highlights a fundamental difference between the theoretical and practical approaches to art.</p>
        <p>When we meet for coffee, our conversations naturally gravitate toward the pragmatics of creation. And if we're being honest; we fucking love that. We obsess over the details of what it takes to build something impactful, focusing on the essential but often overlooked aspects. The fundamentals. The actual tools. The techniques. What is so often overlooked by the suits as too in the weeds. We love being in weeds. We live in the weeds.</p>
        <p>True art's structure is rooted in a constant quest for the right materials, tools, and innovative techniques that can transform our visions into reality. This relentless pursuit is a tangible manifestation of the creative process, extending far beyond mere conceptualization. It's about the hands-on, labor-intensive work that breathes life into ideas.</p>
        <p>Brene Brown captures this sentiment perfectly: "If you're not in the arena with the rest of us, fighting and getting your ass kicked on occasion, I'm not interested in your feedback." This quote underscores the value of experiential knowledge—the kind gained only through active participation and facing challenges head-on.</p>
        <p>Building a company is an intensely personal endeavor. You're shipping part of your soul with levery line of code. With every piece of the company that gets released to the public. It's shaped by us. By our interactions with the world, influenced by our needs, limitations, and resourcefulness. Creating a business is a personal manifestation of how one perceives the world, identifies gaps or needs, and endeavors to fill them in a way that can reshape society. It reflects our unique vision and approach to addressing problems, driven by a deep understanding of the landscape we operate in.</p>
        </div>
        <div className="image">
          <img src={image26} alt="Background" />
        </div>
      </div>
  
      <section className="fullimage">
          <img src={image20} alt="Image 2" className="image" />
          </section>
      
      <div id="you-think-technology-has-limits-wrong" className="halfcontent">
        <div className="text">
        <p>You think technology has limits? Wrong. The notion that we've reached the edge of what we can build is completely misguided and dangerously limiting. We are only at the beginning. The end of year 0. These imaginary boundaries are defined by critics, safely out of the arena.</p>
        <p>Throughout history, every significant technological breakthrough was thought impossible. The idea of powered flight was considered impossible. The internet was written off as a footnote. Laughed at as a trival experiment but now underpins modern society. What we see as limits are often just stepping stones to greater achievements.</p>
        <p>We are on this planet for such a short time that we forget how exceptional humanity has been. We've innovated ourselves out of the dark ages, out of plagues, out of famines. We've created entirely new paradigms. We've redefined what it means to be human several times.</p>
        <p>Ultimately, the belief in technology's limits is a self-fulfilling prophecy. By accepting boundaries, we constrain our potential and stifle the innovative spirit. Instead, we must adopt an attitude of boundless possibility, recognizing that the only true limits are those we impose on ourselves. The future of technology is not predetermined; it is shaped by our vision, ambition, and relentless pursuit of the extraordinary.</p>
        <p>As we exist in the next technology revoltuion remember we have infinite opportunity. And that we can innovate ourselves out of all our problems that we have today; global warming, war, underpopulation, decreasing intelligence.</p>
        </div>
        <div className="image">
          <img src={image29} alt="Background" />
        </div>
      </div>
  
        <section className="fullimage">
        <img src={image30} alt="Image 2" className="image" />
        </section>
      
        {/* <div id="when-art-critics-get-together-they-talk-about-form-and-structure-and-meaning" className="halfcontent">
        <div className="text">
        <p>When art critics get together they talk about Form and Structure and Meaning. When artists get together they talk about where you can buy cheap turpentine.</p> 
        <p>When we meet up for coffee we lean towards the pragmatics of creation. The fundamentals of what it takes for os to build; fundamentals that aren't acknowledged as much as they should in public.</p> 
        <p>The structure of true art is in the constant search for the right materials, the right tools, and the new techniques that can bring our visions to the present. It's a tangible manifestation of the process that extends beyond conceptualizing the act of making.</p> 
        <p><em>"If you're not in the arena with the rest of us, fighting and getting your ass kicked on occasion, I'm not interested in your feedback" Brene Brown</em></p> 
        <p>Building a company is deeply personal. It's shaped by our internations with the world around us, influenced by needs, limitations, and resourcefulness. Company building is a personal manifestation of how one sees the world, identifies gaps or needs and endeavors to fill them in a way that reshapes civilization.</p>
        </div>
        <div className="image">
          <img src={image31} alt="Background" />
        </div>
      </div> */}
{/* 
        <section className="fullimage">
        <img src={image31} alt="Image 2" className="image" />
        </section> */}

        <section className="fullimage">
        <img src={image32} alt="Image 2" className="image" />
        </section>

        <section className="fullimage">
        <img src={image33} alt="Image 2" className="image" />
        </section>

        <section className="fullimage">
        <img src={image34} alt="Image 2" className="image" />
        </section>

        <section className="fullimage">
        <img src={image35} alt="Image 2" className="image" />
        </section>

        <section className="fullimage">
        <img src={image36} alt="Image 2" className="image" />
        </section>

        <section className="fullimage">
        <img src={image37} alt="Image 2" className="image" />
        </section>

        <section className="fullimage">
        <img src={image38} alt="Image 2" className="image" />
        </section>
  
      </>
    );
  }

export default Home;