import React from 'react';
import slide1 from './slides/Slide1.png';
import slide2 from './slides/Slide2.png';
import slide4 from './slides/Slide4.png';
import slide5 from './slides/Slide5.png';
import slide6 from './slides/Slide6.png';
import slide7 from './slides/Slide7.png';
import slide8 from './slides/Slide8.png';
import slide9 from './slides/Slide9.png';
import slide10 from './slides/Slide10.png';
import slide11 from './slides/Slide11.png';
import slide12 from './slides/Slide12.png';
import slide13 from './slides/Slide13.png';
import slide14 from './slides/Slide14.png';
import slide15 from './slides/Slide15.png';
import slide16 from './slides/Slide16.png';
import slide17 from './slides/Slide17.png';
import slide18 from './slides/Slide18.png';

function ProtectedPage() {
  return (
    <div className="protected-page">
      <section className="fullimage">
        <img src={slide1} alt="Image 1" className="image" />
      </section>
      <section className="fullimage">
        <img src={slide2} alt="Image 2" className="image" />
      </section>
      <section className="fullimage">
        <img src={slide4} alt="Image 4" className="image" />
      </section>
      <section className="fullimage">
        <img src={slide5} alt="Image 5" className="image" />
      </section>
      <section className="fullimage">
        <img src={slide6} alt="Image 6" className="image" />
      </section>
      <section className="fullimage">
        <img src={slide7} alt="Image 7" className="image" />
      </section>
      <section className="fullimage">
        <img src={slide8} alt="Image 8" className="image" />
      </section>
      <section className="fullimage">
        <img src={slide9} alt="Image 9" className="image" />
      </section>
      <section className="fullimage">
        <img src={slide10} alt="Image 10" className="image" />
      </section>
      <section className="fullimage">
        <img src={slide11} alt="Image 11" className="image" />
      </section>
      <section className="fullimage">
        <img src={slide12} alt="Image 12" className="image" />
      </section>
      <section className="fullimage">
        <img src={slide13} alt="Image 13" className="image" />
      </section>
      <section className="fullimage">
        <img src={slide14} alt="Image 14" className="image" />
      </section>
      <section className="fullimage">
        <img src={slide15} alt="Image 15" className="image" />
      </section>
      <section className="fullimage">
        <img src={slide16} alt="Image 16" className="image" />  
      </section>
      <section className="fullimage">
        <img src={slide17} alt="Image 17" className="image" />
      </section>
      <section className="fullimage">
        <img src={slide18} alt="Image 18" className="image" />
      </section>
    </div>
  );
}

export default ProtectedPage;