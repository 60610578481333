import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './Home';
import ProtectedPage from './ProtectedPage';
import PasswordEntry from './PasswordEntry';
import './App.css';

function App() {
  return (
    <div className="App">
      <Router>
        <nav className="contents">
          <ul>
            <li><a href="/#repetition-does-not-spoil-the-prayer">Repetition does not spoil the prayer</a></li>
            <li><a href="/#belief-before-ability">Belief before ability</a></li>
            <li><a href="/#focus-on-long-term-excellence">Focus on long term excellence</a></li>
            <li><a href="/#anxious-to-capitalize-on-every-waking-hour">Anxious to capitalize on every waking hour</a></li>
            <li><a href="/#the-ten-commandments-must-be-silenced-when-self-preservation-is-at-stake">The ten commandments must be silenced when self preservation is at stake</a></li>
            <li><a href="/#we-may-not-be-the-first-to-the-party-but-we'll-be-the-best">We may not be the first to the party but we'll be the best</a></li>
            <li><a href="/#fuck-the-safety-net">Fuck the safety net</a></li>
            <li><a href="/#when-art-critics-get-together-they-talk-about-form-and-structure-and-meaning">When art critics get together they talk about form and structure and meaning</a></li>
            <li><a href="/#you-think-technology-has-limits-wrong">You think technology has limits? wrong</a></li>
            <li><Link to="/password-entry">usc engineering slides</Link></li>
          </ul>
        </nav>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/password-entry" element={<PasswordEntry />} />
          <Route path="/protected" element={<ProtectedPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;