import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function PasswordEntry() {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordSubmit = () => {
    if (password === 'fighton') {
      navigate('/protected');
    } else {
      setError('Incorrect password. Please try again.');
    }
  };

  return (
    <div className="password-entry">
      <h2>Enter Password</h2>
      <input
        type="password"
        value={password}
        onChange={handlePasswordChange}
        placeholder="Enter password"
      />
      <button onClick={handlePasswordSubmit}>Submit</button>
      {error && <p>{error}</p>}
    </div>
  );
}

export default PasswordEntry;